<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
				@submit.native.prevent>
				<el-form-item label="姓名:" label-width="44px" class="w-200">
					<el-input v-model="table.where.name" placeholder="请输入姓名" clearable />
				</el-form-item>
				<el-form-item label="手机号:" label-width="62px" class="w-200">
					<el-input v-model="table.where.phone" placeholder="请输入手机号" clearable />
				</el-form-item>
				<el-form-item label="车牌号:" label-width="62px" class="w-200">
					<el-input v-model="table.where.car_number" placeholder="请输入车牌号" clearable />
				</el-form-item>

				<!-- <el-form-item label="载客状态:" label-width="35px" class="w-170">
          <el-select v-model="table.where.load_status" placeholder="请选择" clearable class="ele-fluid">
            <el-option  label="全部" value=0 />
            <el-option  label="载客" value=1 />
            <el-option label="空车" value=2 />
          </el-select>
        </el-form-item>
        
        <el-form-item label="在线状态:" label-width="35px" class="w-170">
          <el-select v-model="table.where.atline_status" placeholder="请选择" clearable class="ele-fluid">
            <el-option  label="全部" value=0 />
            <el-option  label="在线" value=1 />
            <el-option label="下线" value=2 />
          </el-select>
        </el-form-item> -->

				<el-form-item label="省:" label-width="35px" class="w-170">
					<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省'
						clearable>
						<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市:" label-width="35px" class="w-150">
					<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid), $forceUpdate()"
						placeholder='请选择市' clearable>
						<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="县/区:" label-width="58px" class="w-200 mr-20">
					<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
						<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
							:label="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
						class="ele-btn-icon">搜索</el-button>
					<el-button @click="reSetWhere">重置</el-button>
					<download style="margin-left: 0" class="ele-btn-icon ml-20" label="导出" url="driver/exportExcel"
						:outtime='4000' v-if="permission.includes('sys:driver:export')" :params="exportOrderParams()"
						ref="childDownload" />
				</el-form-item>

			</el-form>
			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row :stripe=true>
				<template slot-scope="{index}">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />

					<el-table-column prop="true_name" label="真实姓名" show-overflow-tooltip />
					<el-table-column prop="sex" label="性别" show-overflow-tooltip>
						<template slot-scope="{row}">{{ row.sex == 0 ? '男' : '女' }}</template>
					</el-table-column>
					<el-table-column prop="driver_type" label="司机类型" show-overflow-tooltip>
						<template slot-scope="{row}">{{ row.driver_type == 1 ? '租车' : (row.driver_type == 2 ? '车主' : '/')
						}}</template>
					</el-table-column>
					<el-table-column prop="phone" label="手机号" show-overflow-tooltip min-width="120" />
					<el-table-column prop="car_number" label="车牌号" show-overflow-tooltip min-width="100" />
					<el-table-column prop="driver_area" label="所属地区" show-overflow-tooltip min-width="220" />
					<el-table-column prop="driver_areas_name" label="可接单区域" show-overflow-tooltip min-width="220" />

					<el-table-column prop="balck_count" label="被乘拉黑次数(次)" align="center" show-overflow-tooltip
						min-width="140" />


					<el-table-column prop="car_name" label="车辆类型" align="center" show-overflow-tooltip min-width="110" />
					<!-- <el-table-column prop="order_rule" label="服务费收取比例（%）" show-overflow-tooltip min-width="220" /> -->
					<el-table-column prop="car_color" label="车辆颜色" align="center" show-overflow-tooltip min-width="110" />
					<el-table-column prop="money" label="累计接单收益(元)" show-overflow-tooltip />
					<el-table-column prop="order_total" label="累计接单量(笔)" show-overflow-tooltip />
					<el-table-column prop="invite_money" label="累计推广收益(元)" show-overflow-tooltip />
					<el-table-column prop="invite_num" label="累计推广量(位)" show-overflow-tooltip />
					<el-table-column prop="credit" label="评分" show-overflow-tooltip />
					<el-table-column prop="total_time" label="累计在线时长(分钟)" show-overflow-tooltip min-width="145" />

					<!-- <el-table-column prop="atline_status" label="在线状态" show-overflow-tooltip min-width="145" />
          <el-table-column prop="load_status" label="载客状态" show-overflow-tooltip min-width="145" /> -->


					<el-table-column label="最近登录时间" show-overflow-tooltip min-width="120">
						<template slot-scope="{row}">{{ row.active_time * 1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column prop="online_order_nums" label="进行中订单(笔)" show-overflow-tooltip min-width="180" />
					<el-table-column label="注册时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.create_time * 1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column label="操作" width="180px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link @click="handlecheck(row)" icon="el-icon-view" type="primary" :underline="false"
								v-if="permission.includes('sys:driver:view')">查看</el-link>
							<el-link @click="handleEdit(row)" icon="el-icon-edit" type="primary" :underline="false"
								v-if="permission.includes('sys:driver:edit')">编辑</el-link>
							<el-dropdown @command="command => dropClick(command, row)">
								<span class="el-dropdown-link" style="margin-left: 10px;">
									<el-link type="primary" icon="el-icon-more" :underline="false"></el-link>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item icon="el-icon-s-order"
										v-if="permission.includes('sys:driver:out_list')" command="out_list">出车记录
									</el-dropdown-item>
									<el-dropdown-item icon="el-icon-user-solid" command="check_list"
										v-if="permission.includes('sys:driver:check_list')">人脸检测</el-dropdown-item>
									<el-dropdown-item icon="el-icon-delete" v-if="permission.includes('sys:driver:delete')"
										command="remove">删除
									</el-dropdown-item>
									<el-dropdown-item icon="el-icon-s-release"
										v-if="permission.includes('sys:driver:forblack')" command="blackData">加入黑名单
									</el-dropdown-item>
									<el-dropdown-item icon="el-icon-error" v-if="permission.includes('sys:driver:forlimit')"
										command="forlimit">限号
									</el-dropdown-item>
									<el-dropdown-item icon="el-icon-error"
										v-if="permission.includes('sys:driver:limitWithDraw') && row.d_is_tx == 1"
										command="limitWithDraw">限制提现
									</el-dropdown-item>
									<el-dropdown-item icon="el-icon-s-custom" v-if="permission.includes('sys:driver:black')"
										command="shieldDetail">
										被乘拉黑明细
									</el-dropdown-item>
									<el-dropdown-item icon="el-icon-error"
										v-if="permission.includes('sys:driver:openWithDraw') && row.d_is_tx == 0"
										command="openWithDraw">恢复提现
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
		<!-- 查看司机详情 -->
		<el-dialog v-dialogDrag title="查看司机详情" :visible.sync="dialogTableVisibleCheck" width="800px"
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editForm" label-width="170px">
					<div style="display: flex;">
						<div style="padding: 0 40px 0 30px;border-right: 1px solid #C6C6C6;">
							<el-form-item label="今日取消订单量:" prop="money">
								{{ checkForm.today_cancel }}
							</el-form-item>
							<el-form-item label="本月累计取消订单量:" prop="invite_money">
								{{ checkForm.thisMon_cancel }}
							</el-form-item>
							<el-form-item label="本月累计接单量：" prop="total_time">
								{{ checkForm.month_order_total }}
							</el-form-item>
							<el-form-item label="本月总收益：" prop="money">
								{{ checkForm.month_order_price }}
							</el-form-item>
							<el-form-item label="本月订单收益：" prop="invite_money">
								{{ checkForm.month_order_earning }}
							</el-form-item>
							<el-form-item label="本月红包收益：" prop="total_time">
								{{ checkForm.month_order_fee }}
							</el-form-item>
							<el-form-item label="本月推广收益：" prop="total_time">
								{{ checkForm.month_order_promote }}
							</el-form-item>
							<el-form-item label="司机姓名：" prop="name">
								{{ checkForm.true_name }}
							</el-form-item>
							<el-form-item label="头像：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.true_head"
									fit="cover" :preview-src-list="[checkForm.true_head]"></el-image>
							</el-form-item>
							<el-form-item label="是否载客" prop="phone">
								{{ checkForm.is_carry }}
							</el-form-item>
							<el-form-item label="手机号：" prop="phone">
								{{ checkForm.phone }}
							</el-form-item>
							<el-form-item label="所属区域：">
								{{ checkForm.driver_area }}
							</el-form-item>
							<el-form-item label="累计接单收益：" prop="money">
								{{ checkForm.money }}
							</el-form-item>
							<el-form-item label="诚信度：" prop="invite_money">
								{{ checkForm.credit }}
							</el-form-item>
							<el-form-item label="累计在线时长：" prop="total_time">
								{{ checkForm.total_time }}分钟
							</el-form-item>
							<el-form-item label="最近的登录时间：" prop="money">
								{{ checkForm.active_time * 1000 | toDateString }}
							</el-form-item>
							<el-form-item label="注册时间：" prop="invite_money">
								{{ checkForm.create_time * 1000 | toDateString }}
							</el-form-item>
							<el-form-item label="性别：">
								{{ checkForm.sex == 0 ? '男' : '女' }}
							</el-form-item>
							<el-form-item label="真实姓名：">
								{{ checkForm.true_name }}
							</el-form-item>
							<el-form-item label="司机类型：">
								{{ checkForm.driver_type == 1 ? '租车' : (checkForm.driver_type == 2 ? '车主' : '/') }}
							</el-form-item>
							<el-form-item label="车辆类型：">
								{{ checkForm.car_name }}
							</el-form-item>
							<el-form-item label="车辆颜色：">
								{{ checkForm.car_color }}
							</el-form-item>
						</div>
						<div>
							<el-form-item label="身份证号：" prop="idcard">
								{{ checkForm.idcard }}
							</el-form-item>
							<el-form-item label="支付宝账号：" prop="idcard">
								{{ checkForm.ali_number }}
							</el-form-item>
							<el-form-item label="车牌号：" prop="car_number">
								{{ checkForm.car_number }}
							</el-form-item>
							<el-form-item label="手持身份证照片：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.idcard_hand"
									fit="cover" :preview-src-list="[checkForm.idcard_hand]"></el-image>
								<!--								<img :src="checkForm.idcard_hand" width="70"   height="70" />-->
							</el-form-item>
							<el-form-item label="身份证正面照：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.idcard_front"
									fit="cover" :preview-src-list="[checkForm.idcard_front]"></el-image>
								<!--								<img :src="checkForm.idcard_front" width="70"   height="70" />-->
							</el-form-item>
							<el-form-item label="身份证反面照：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.idcard_back"
									fit="cover" :preview-src-list="[checkForm.idcard_back]"></el-image>

								<!--								<img :src="checkForm.idcard_back" width="70"   height="70" />-->
							</el-form-item>
							<el-form-item label="驾驶证正面照：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.jiacard_front"
									fit="cover" :preview-src-list="[checkForm.jiacard_front]"></el-image>

								<!--								<img :src="checkForm.jiacard_front" width="70"   height="70" />-->
							</el-form-item>
							<el-form-item label="行驶证正面照：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.xingcard_front"
									fit="cover" :preview-src-list="[checkForm.xingcard_front]"></el-image>

								<!--								<img :src="checkForm.xingcard_front" width="70"   height="70" />-->
							</el-form-item>
							<el-form-item label="车辆正面照：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.car_front"
									fit="cover" :preview-src-list="[checkForm.car_front]"></el-image>

								<!--								<img :src="checkForm.car_front" width="70"   height="70" />-->
							</el-form-item>
							<el-form-item label="从业资格证：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.taxi_operation"
									fit="cover" :preview-src-list="[checkForm.taxi_operation]"></el-image>
								<!--								<img :src="checkForm.taxi_operation" width="70"   height="70" />-->
							</el-form-item>
							<el-form-item label="道路运输证：">
								<el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.road_transport"
									fit="cover" :preview-src-list="[checkForm.road_transport]"></el-image>
								<!--								<img :src="checkForm.road_transport" width="70"   height="70" />-->
							</el-form-item>


						</div>
					</div>
				</el-form>
			</el-card>
		</el-dialog>
		<!-- 编辑司机 -->
		<el-dialog v-dialogDrag title="编辑司机" :visible.sync="dialogTableVisibleEdit" :destroy-on-close="true"
			:lock-scroll="false" @closed="[editForm = {}, $refs.table.reload()]">
			<el-card shadow="never">
				<el-form :model="editForm" ref="editForm" :rules="editRules" label-width="170px">
					<el-form-item label="真实姓名：" prop="name">
						<el-input v-model="editForm.true_name" placeholder="请输入" class="input216" />
					</el-form-item>
					<el-form-item label="联系方式：" prop="phone">
						<el-input v-model="editForm.phone" placeholder="请输入" class="input216" />
					</el-form-item>
					<div>
						<el-form-item label="所属区域：" prop="pid">
							<el-select v-model="editForm.pname" @change="handleChangeProv1(editForm.pname)"
								placeholder="请选择省" class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.cname" @change="handleChangeCity1(editForm.cname)"
								placeholder="请选择市" class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.aname" @change="$forceUpdate(), handleDis(editForm.aname)"
								placeholder="请选择区/县" class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<!--					<el-form-item label="可接单区域：">-->
					<!--						{{editForm.driver_areas_name}}-->
					<!--					</el-form-item>-->
					<div>
						<el-form-item label="可接单区域：" prop="pid">
							<el-select v-model="editForm.jd_pname" placeholder="请选择省" class="selectStyle mr-10 mb-20"
								:disabled="true" :readonly="true">
								<el-option v-for="option in jd_provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.jd_cname" placeholder="请选择市" class="selectStyle mr-10 mb-20"
								:disabled="true" :readonly="true">
								<el-option v-for="option in jd_cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.jd_aid" @change="handleDis2" class="ele-block" clearable filterable
								multiple>
								<el-option v-for="option in jd_districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<el-form-item label="累计接单收益：">
						{{ editForm.money }}
					</el-form-item>
					<el-form-item label="推广总收益：">
						{{ editForm.invite_money }}
					</el-form-item>
					<el-form-item label="累计在线时长：">
						{{ editForm.total_time }}
					</el-form-item>
					<el-form-item label="诚信度：" prop="credit">
						<el-input v-model="editForm.credit" placeholder="请输入" class="input216" />
					</el-form-item>
					<el-form-item label="性别：" prop="sex">
						<!-- {{editForm.sex}} -->
						<el-radio-group v-model="editForm.sex">
							<el-radio :label="0">男</el-radio>
							<el-radio :label="1">女</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="司机类型：" prop="driver_type">
						<el-radio-group v-model="editForm.driver_type">
							<el-radio :label="1">租车</el-radio>
							<el-radio :label="2">车主</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="车辆类型：" prop="car_type">
						<el-radio-group v-model="editForm.car_type">
							<el-radio :label="1">燃油/气车</el-radio>
							<el-radio :label="2">新能源</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="爱心标记：" prop="push_type">
						<el-radio-group v-model="editForm.push_type">
							<el-radio :label="1">是</el-radio>
							<el-radio :label="0">否</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="车辆颜色：" prop="car_color_id">
						<span v-for=" (item, index) in editForm.car_color_ids" :key="index" style="margin-left: 15px;">
							<el-radio-group v-model="editForm.car_color_id">
								<el-radio :label="item.id">{{ item.car_color }}</el-radio>
							</el-radio-group>
						</span>
						<!-- {{ editForm.car_color_ids }} -->
					</el-form-item>


					<el-form-item label="身份证号：" prop="idcard">
						<el-input v-model="editForm.idcard" placeholder="请输入身份证号" class="input216" />
					</el-form-item>
					<el-form-item label="车牌号：" prop="car_number">
						<el-input v-model="editForm.car_number" placeholder="请输入车牌号" class="input216" />
					</el-form-item>
					<el-form-item label="车座数量：" prop="car_persons">
						<el-input v-model="editForm.car_persons" placeholder="请输入车座数量" class="input216" />
					</el-form-item>
					<el-form-item label="真实头像：" prop="true_head">
						<uploadImage :limit="1" v-model="editForm.true_head"></uploadImage>
					</el-form-item>
					<el-form-item label="手持身份证照片：" prop="idcard_hand">
						<uploadImage :limit="1" v-model="editForm.idcard_hand"></uploadImage>
					</el-form-item>
					<el-form-item label="身份证正面照：" prop="idcard_front">
						<uploadImage :limit="1" v-model="editForm.idcard_front"></uploadImage>
					</el-form-item>
					<el-form-item label="身份证反面照：" prop="idcard_back">
						<uploadImage :limit="1" v-model="editForm.idcard_back"></uploadImage>
					</el-form-item>
					<el-form-item label="驾驶证正面照：" prop="jiacard_front">
						<uploadImage :limit="1" v-model="editForm.jiacard_front"></uploadImage>
					</el-form-item>
					<el-form-item label="行驶证正面照：" prop="xingcard_front">
						<uploadImage :limit="1" v-model="editForm.xingcard_front"></uploadImage>
					</el-form-item>
					<el-form-item label="车辆正面照：" prop="car_front">
						<uploadImage :limit="1" v-model="editForm.car_front"></uploadImage>
					</el-form-item>
					<el-form-item label="从业资格证：" prop="taxi_operation">
						<uploadImage :limit="1" v-model="editForm.taxi_operation"></uploadImage>
					</el-form-item>
					<el-form-item label="道路运输证：" prop="road_transport">
						<uploadImage :limit="1" v-model="editForm.road_transport"></uploadImage>
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="dialogTableVisibleEdit = false">取消</el-button>
				<el-button type="primary" @click="save">保存</el-button>
			</div>
		</el-dialog>
		<!-- 查看出车记录 -->
		<el-dialog v-dialogDrag title="查看出车记录" :visible.sync="dialogTableVisibleCarRecords" class="records"
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<div style="margin-left:28px">
					<div><span class="foWei6">司机姓名：</span>{{ driverData.true_name }} </div>
					<div><span class="foWei6">类型：</span>{{ driverData.type }} </div>
					<div><span class="foWei6">手机号：</span>{{ driverData.phone }} </div>
					<div><span class="foWei6">地区：</span>{{ driverData.area }} </div>
				</div>
				<div class="greenLine"></div>
				<div class="statist" style="margin-left: 30px;">出车统计</div>
				<div id="main" style="width:100%;height:300px;"></div>
			</el-card>
		</el-dialog>


		<el-dialog v-dialogDrag title="确认加入黑名单" :visible.sync="dialogVisiblePlans" width="800px" :destroy-on-close="true"
			:lock-scroll="false">
			<el-form :model="blackReason" ref="blackReason" @submit.native.prevent :rules="editRules"
				style="margin: 40px 0;">
				<el-form-item label="拉黑原因:" label-width="120px" prop="black_data">
					<el-input v-model="blackReason.black_data" placeholder="拉黑原因" style="width: 580px;" />
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button @click="dialogVisiblePlans = false">取消</el-button>
				<el-button type="primary" @click="blackData()">确认</el-button>
			</div>
		</el-dialog>

		<!-- 检测记录 -->
		<el-dialog v-dialogDrag title="检测记录" :visible.sync="dialogTableVisibleRecords" width="1000px"
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="table1.where" label-width="77px" class="ele-form-search mb-10 d-flex" style="float:right">
					<el-form-item label="开始时间:">
						<el-date-picker style="width:150px" v-model="tableend_time" type="date" placeholder="选择日期" clearable
							@change="birthdayChange1" value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="结束时间:">
						<el-date-picker style="width:150px" v-model="tablestart_time" type="date" placeholder="选择日期"
							clearable @change="birthdayChange2" value-format="yyyy-MM-dd">
						</el-date-picker>
						<el-button type="primary" @click.native="searchRecords()" icon="el-icon-search"
							class="ele-btn-icon  ml-20">搜索</el-button>
					</el-form-item>
				</el-form>
				<el-table :data="recordsList" element-loading-text="列表正在加载中" border fit highlight-current-row
					style="width: 100%;">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" label="编号" width="60" align="center" fixed="left" show-overflow-tooltip />
					<el-table-column prop="true_name" label="司机姓名" show-overflow-tooltip min-width="120" />
					<el-table-column prop="car_number" label="司机车牌号" show-overflow-tooltip min-width="120" />
					<el-table-column prop="phone" label="司机电话" show-overflow-tooltip min-width="120" />
					<el-table-column prop="true_head" label="司机认证人像" show-overflow-tooltip min-width="80">
						<template slot-scope="{row}">
							<el-image style="width:25px;height:25px;" :src="row.true_head" fit="cover"
								:preview-src-list="[row.true_head]">
								<div slot="error" class="image-slot">
									<i class="el-icon-picture-outline"></i>
								</div>
							</el-image>
						</template>
					</el-table-column>
					<el-table-column prop="create_time" label="出/收车检测时间" show-overflow-tooltip min-width="160" />
					<el-table-column prop="face_img" label="检测上传图片" show-overflow-tooltip min-width="80">
						<template slot-scope="{row}">
							<el-image style="width:25px;height:25px;" :src="row.face_img" fit="cover"
								:preview-src-list="[row.face_img]">
								<div slot="error" class="image-slot">
									<i class="el-icon-picture-outline"></i>
								</div>
							</el-image>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination v-if="recordsShow" @current-change="handleCurrentChange" :current-page.sync="pageNumber"
					layout="total, prev, pager, next, jumper" :page-sizes="[5, 10, 15, 20]" :page-size="10"
					:total="recordsTotal">
				</el-pagination>
			</el-card>
		</el-dialog>
		<!-- 被乘拉黑明细 -->
		<el-dialog v-dialogDrag title="被乘拉黑明细" :visible.sync="dialogShieldDetail" width="1200px" :destroy-on-close="true"
			:lock-scroll="false">
			<div style="display: flex; margin: 20px 0;">
				<div class="info" style="font-size: 14px;color: #262626;font-weight:550">司机姓名:{{ info.name }}</div>
				<div class="info" style="font-size: 14px;color: #262626;font-weight:550">车牌号:{{ info.number }}</div>
				<div class="info" style="font-size: 14px;color: #262626;font-weight:550">手机号:{{ info.phone }}</div>
			</div>
			<el-table :data="shieldList" element-loading-text="列表正在加载中" border fit highlight-current-row
				style="width: 100%;">
				<el-table-column type="selection" width="45" align="center" fixed="left" />
				<el-table-column type="index" label="序号" width="60" align="center" fixed="left" show-overflow-tooltip />
				<el-table-column prop="order_id" label="对应拉黑订单" align="center" show-overflow-tooltip min-width="200" />
				<el-table-column prop="user_phone" label="乘客账号" align="center" show-overflow-tooltip min-width="150" />
				<el-table-column prop="black_reason" label="拉黑原因" align="center" show-overflow-tooltip min-width="200" />
				<el-table-column prop="create_time" label="拉黑时间" align="center" show-overflow-tooltip min-width="160" />
				<el-table-column prop="create_time" label="查看订单" align="center" show-overflow-tooltip min-width="180">
					<template slot-scope="{row}">
						<el-link @click="viewOrderChat(row)" icon="el-icon-view" type="primary" :underline="false"
							v-if="row.channel == 1 || row.channel == 3">查看订单聊天</el-link>
						<el-link @click="viewOrderDetail(row)" icon="el-icon-edit" type="primary" :underline="false"
							v-if="row.channel == 2 || row.channel == 4">查看订单详情</el-link>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="shieldShow" @current-change="shieldCurrentChange" :current-page.sync="shieldPageNumber"
				layout="total, prev, pager, next, jumper" :page-sizes="[5, 10, 15, 20]" :page-size="10" prev-text next-text
				:total="count">
			</el-pagination>
		</el-dialog>
		<!-- 聊天记录 -->
		<el-dialog v-dialogDrag title="聊天记录" :visible.sync="dialogTableVisibleChatRecord" class="records"
			@close='closeDialog' :destroy-on-close="true" :lock-scroll="false" width="600px">
			<div class="charRecord-box">
				<div class="driverBox" style="padding:20px;width: 500px;">
					<div class="" style="width:80px;height:80px;">
						<img style="width:60px;height:60px;border-radius:50%" :src="driverInfo.headimg" />
					</div>
					<div class="">
						<div class="" style="font-size:17px;margin:5px 0">{{ driverInfo.name }}</div>
						<div class="" style="font-size:13px">{{ driverInfo.car_number }}</div>
					</div>
				</div>
				<div>
					<div class="charRecord-inner" v-for="(option, index) in charList" :key="option.id">
						<!-- 时间 -->
						<!-- <div class="w-100" v-if="option.isTimeShow==1 && option.p_show==1 && option.type !== 33 && option.type !== 34"> -->
						<div class="w-100"
							v-if="option.p_show == 1 && option.type !== 33 && option.type !== 34 && option.content.S != 89 && option.content.S != 38 && option.content.S != 94 && option.content.S != 27">
							<div class="charRecord-time">{{ option.create_time }}</div>
						</div>
						<!-- 系统消息-->
						<div class="w-100"
							v-if="(option.content.S == 5 || option.content.S == 100 || option.content.S == 1 || option.content.S == 20 || option.content.S == 39) && option.p_show == 1">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" src="./logo1.png">
								</div>
								<div class="hh"></div>
								<div class="charRecord-title5 p-10">{{ option.content.M }}</div>
							</div>
						</div>
						<!-- 左侧 完善目的地-->
						<div class="w-100" v-if="option.content.S == 27 && option.direction == 3">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" src="./logo1.png">
								</div>
								<div class="hh"></div>
								<div class="charRecord-title ">
									<div class="">
										<div class="m-10 mb-0">您可以完善一下目的地</div>
										<div class="whiteAddress">{{ orderInfo.place_address }}</div>
										<div class="addressBtn">
											<span class="ignore">忽略</span>
											<span class="submit">提交</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 右侧 完善目的地-->
						<div class="w-100" v-if="option.content.S == 27 && option.direction == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 ">
									<div class="">
										<div class="m-10 mb-0">您可以完善一下目的地</div>
										<div class="whiteAddress">请输入您要去的地址</div>
										<div class="addressBtn">
											<span class="ignore" style="width: 100%;border-right: none">已忽略</span>
										</div>
									</div>
								</div>
								<div class="rhh"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!--						右文字信息-->
						<div class="w-100" v-if="option.content.S == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10">{{ option.content.M }}</div>
								<div class="rhh"></div>
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 左侧 语音-->
						<div class="w-100 " v-if="option.content.S == 21 && option.direction == 2">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>
								<div class="charRecord-title2 p-10 " :style="{ width: option.bgWidth }"
									@click="handlePlay(option.content.P.URL, option.id, index)"
									v-if="option.isPlay == true">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle' + index">您的浏览器不支持</audio>
									<div class="lvoide1">
										<img src="../../../assets/images/ic_b_yuyin@2x.png">
									</div>
									<div>{{ option.content.P.LONG }}''</div>
								</div>
								<div class="charRecord-title2 p-10" :style="{ width: option.bgWidth }"
									v-if="option.isPlay == false">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle' + index">您的浏览器不支持</audio>
									<div class="lvoide1">
										<img src="../../../assets/images/ic_b_yuyin.gif">
									</div>
									<div>{{ option.content.P.LONG }}''</div>
								</div>
							</div>
						</div>
						<!-- 右侧 语音-->
						<div class="w-100 " v-if="option.content.S == 21 && option.direction == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 bgGreen " :style="{ width: option.bgWidth }"
									@click="handlePlay(option.content.P.URL, option.id, index)"
									v-if="option.isPlay == true">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle' + index">您的浏览器不支持</audio>
									<div style="color:#fff">{{ option.content.P.LONG }}''</div>
									<div class="rvoide1">
										<img src="../../../assets/images/yuyin-ico.png" :ref="'changeImg' + index">
									</div>

								</div>
								<div class="charRecord-title2 p-10 bgGreen" :style="{ width: option.bgWidth }"
									v-if="option.isPlay == false">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle' + index">您的浏览器不支持</audio>

									<div style="color:#fff">{{ option.content.P.LONG }}''</div>
									<div class="rvoide1">
										<img src="../../../assets/images/yuyin-gif.gif">
									</div>

								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 图片-->
						<div class="w-100" v-if="option.content.S == 22">
							<div class="charRecord-rightItem">
								<div class="charRecord-title1 p-10" @click="hanlePicture(option.content.P.URL)">
									<img class="" style="max-width: 100px;" :src="option.content.P.URL">
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">
									<img width='100px' height='100px' class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 视频-->
						<div class="w-100" v-if="option.content.S == 6">
							<div class="charRecord-rightItem">
								<div class="charRecord-title1 p-10">
									<video width="100" height="100" controls>
										<source :src="option.content.P.URL" type="video/mp4">
									</video>
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">

									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 地图-->
						<div class="w-100" v-if="option.content.S == 26 && option.direction == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-titleMap p-10">
									<div class="amap-page-container">
										<div>{{ option.content.P.ADDRESS }}</div>
										<div>{{ option.content.P.TADDRESS }}</div>
										<div :style="{ width: '100%', height: '180px' }">
											<!-- vid="amapDemo1" -->

											<el-amap ref="map" :zoom="zoom" :center="option.content.P.POINT">
												<el-amap-marker></el-amap-marker>
											</el-amap>
										</div>
									</div>
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 左侧 地图-->
						<div class="w-100" v-if="option.content.S == 26 && option.direction == 2">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>
								<div class="charRecord-titleMap p-10">
									<div class="amap-page-container">
										<div :style="{ width: '100%', height: '180px' }">
											<el-amap ref="map" :zoom="zoom" :center="option.content.P.POINT">
												<el-amap-marker></el-amap-marker>
											</el-amap>
										</div>
									</div>
								</div>


							</div>
						</div>

						<!-- 左侧 语音通话 对方已取消-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 3 && option.p_show == 1">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3grey@2x.png">
									</div>
									<div>{{ option.content.P.voiceCallMessage }}</div>
								</div>
							</div>
						</div>
						<!-- 左侧 语音通话 已拒接-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 4 && option.p_show == 1">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3grey@2x.png">
									</div>
									<div>{{ option.content.P.message }}</div>
								</div>
							</div>
						</div>
						<!-- 左侧 语音通话 未接听-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 5 && option.p_show == 1">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/call_ic_blackwait.png">
									</div>
									<div>{{ option.content.P.voiceCallMessage }}</div>
								</div>
							</div>
						</div>
						<!-- 左侧 语音通话 通话时长-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 6">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phonegrey@2x.png">
									</div>
									<div>{{ option.content.P.message }}</div>
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 已取消-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 3 && option.p_show == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3@2x.png">
									</div>
									<div>{{ option.content.P.message }}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 对方已拒接-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 4 && option.p_show == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3@2x.png">
									</div>
									<div>{{ option.content.P.voiceCallMessage }}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 对方无应答-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 5 && option.p_show == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/call_ic_whitewait.png">
									</div>
									<div>{{ option.content.P.message }}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 通话时长-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 6">
							<!--							v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==6 && option.p_show==1">-->
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone@2x.png">
									</div>
									<div>{{ option.content.P.message }}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 左侧 订单账单-->
						<div class="w-100" v-if="option.content.S == 31">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" src="./logo1.png" />
								</div>
								<div class="hh"></div>
								<div class="charRecord-Paytitle">
									<div class="w-30">
										<div v-if="option.content.R == 2">
											<div class="m-10 mb-0 text-center">空驶补偿账单</div>
										</div>
										<div v-else>
											<div class="m-10 mb-0 text-center">订单账单</div>
										</div>

										<div class="m-10 mb-0 text-center">
											<span class="price">{{ option.show_data.price }}</span>元
										</div>
										<div class="p-10 text-center payBox">
											<div class="payTitle">行程车费</div>
											<div class="payPrice">{{ option.show_data.price }}元</div>
										</div>
										<div class="p-10  payBox">
											<div class="payTitle">零钱抵现</div>
											<div class="payPrice"><span style="color: #FF9D00;">-{{ option.show_data.bonus
											}}</span>元</div>
										</div>
										<div class="p-10 payType">
											<div>
												<img src="../../../assets/images/share_ic_wechat@2x.png"
													style="float: left;" alt="" width="30px" height="30px;">
												<span class="wxPay">微信支付</span>
											</div>

											<img src="../../../assets/images/ic_arrow@2x.png"
												style="float: right;margin-top: 3px;" alt="" width="10px" height="20px;">

										</div>
										<!--										<div class="p-10 payType borderTop">-->
										<!--											<div>-->
										<!--												<img src="../../../assets/images/ic_chat_pt_pay_alipay.png"-->
										<!--													style="float: left;" alt="" width="30px" height="30px;">-->
										<!--												<span class="wxPay">支付宝支付</span>-->
										<!--											</div>-->

										<!--											<img src="../../../assets/images/ic_arrow@2x.png"-->
										<!--												style="float: right;margin-top: 3px;" alt="" width="10px"-->
										<!--												height="20px;">-->
										<!--										</div>-->
										<div class="p-10 payType borderTop">
											<div>
												<img src="../../../assets/images/ic_pay@2x.png" style="float: left;" alt=""
													width="30px" height="30px;">
												<span class="wxPay">线下支付</span>
											</div>

											<img src="../../../assets/images/ic_arrow@2x.png"
												style="float: right;margin-top: 3px;" alt="" width="10px" height="20px;">
										</div>


									</div>
								</div>

							</div>
						</div>
						<!-- 左侧 违约金账单-->
						<div class="w-100" v-if="option.content.S == 46">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" src="./logo1.png" />
								</div>
								<div class="hh"></div>
								<div class="charRecord-Paytitle">
									<div class="w-30">
										<div class="m-10 mb-0 text-center">违约金账单</div>
										<div class="m-10 mb-0 text-center">
											<span class="price">{{ option.content.P.PRICE }}</span>元
										</div>
										<div class="p-10 payType">
											<div>
												<img src="../../../assets/images/share_ic_wechat@2x.png"
													style="float: left;" alt="" width="30px" height="30px;">
												<span class="wxPay">微信支付</span>
											</div>

											<img src="../../../assets/images/ic_arrow@2x.png"
												style="float: right;margin-top: 3px;" alt="" width="10px" height="20px;">

										</div>

									</div>
								</div>

							</div>
						</div>
						<!-- 右侧 违约金支付成功-->
						<div class="w-100" v-if="option.content.S == 36 && option.p_show == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-Paytitle ">
									<div>
										<div class="paySuccess">支付成功</div>
										<div class="money">
											<span style="color:#84B625;font-size:25px;text-align:center">{{
												option.content.PRICE }}</span>元
										</div>
									</div>

								</div>
								<div class="rhh"></div>
								<div class="charRecordRightImage">
									<img class="charHeadimg" :src="option.user.headimg" />
								</div>

							</div>
						</div>
						<!-- 右侧 支付成功-->
						<div class="w-100" v-if="option.content.S == 32 && option.p_show == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-Paytitle ">
									<div>
										<div class="paySuccess">支付成功</div>
										<div class="money">
											<span style="color:#84B625;font-size:25px;text-align:center">{{
												option.content.PRICE }}</span>元
										</div>
										<div class="otherPay">红包另支付0元</div>
									</div>

								</div>
								<div class="rhh"></div>
								<div class="charRecordRightImage">
									<img class="charHeadimg" :src="option.user.headimg" />
								</div>

							</div>
						</div>

						<!-- 左侧 被动红包 -->
						<div class="w-100" v-if="option.content.S == 72">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>
								<div class="charRecord-Paytitle">
									<div class="">
										<div class="text-align pbt-10">对话红包</div>
										<div class="bill-box">
											<div class="">红包金额</div>
											<div class=""><span>{{ option.content.P.PRICE }}</span>元</div>
										</div>
										<div class="btn">
											<div class="btn-ignore">驳回</div>
											<div class="line"></div>
											<div class="btn-ignore">发红包</div>
										</div>

									</div>
								</div>
							</div>
						</div>
						<!-- 右侧 被动红包-->
						<div class="w-100" v-if="option.content.S == 69">
							<div class="charRecord-rightItem">
								<div class="charRecord-Paytitle">
									<div class="">
										<div class="text-align pbt-10">对话红包</div>
										<div class="bill-box">
											<div class="">红包金额</div>
											<div class=""><span>0.01</span>元</div>
										</div>
										<div class="btn">
											<div class="btn-ignore text-align">已驳回</div>
										</div>
									</div>
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 未领取主动红包-->
						<div class="w-100" v-if="option.content.S == 71 && option.content.H == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title3">
									<img src="../../../assets/images/bg_hb1@2x.png"
										style="position:relative;width:100%;height:100%" />

									<div class="red-box" style="position:absolute;top:30px">
										<div>
											<img class="package" src="../../../assets/images/img_hb1@2x.png" />
										</div>
										<div class="">
											<div class="">主动红包，司机可领取</div>
											<div class="font--9">未领取</div>
										</div>
									</div>
									<div class="address-box" style="position:absolute;top:64px">
										<div class="">
											<img class="weizhi" src="../../../assets/images/hb_logo@2x.png" />
										</div>
										<div class="font--9">咱县打车</div>
									</div>
								</div>

								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 已领取主动红包-->
						<div class="w-100" v-if="option.content.S == 71 && option.content.H == 2">
							<div class="charRecord-rightItem">
								<div class="charRecord-title3">
									<img src="../../../assets/images/bg_hb2@2x.png"
										style="position:relative;width:100%;height:100%" />

									<div class="red-box" style="position:absolute;top:30px">
										<div>
											<img class="package" src="../../../assets/images/img_hb2@2x.png" />
										</div>
										<div class="">
											<div class="">主动红包，司机可领取</div>
											<div class="font--9">未领取</div>
										</div>
									</div>
									<div class="address-box" style="position:absolute;top:64px">
										<div class="">
											<img class="weizhi" src="../../../assets/images/hb_logo@2x.png" />
										</div>
										<div class="font--9">咱县打车</div>
									</div>
								</div>

								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="ampBox" :style="{ display: ampDisplay == true ? 'block' : 'none' }" @click="handleClose">
				<img :src="ampSrc">
			</div>
		</el-dialog>
		<!-- 查看订单详情 -->
		<el-dialog v-dialogDrag title="查看订单详情" :visible.sync="dialogViewOrderDetail" @close='closeShow'
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never" v-if="isReloadData">
				<el-form :model="telephoneCallForm" label-width="140px">
					<div style="display: flex;">
						<div style="width:100%;box-sizing: border-box;padding: 0 50px 0 10px;">
							<el-form-item label="发单时间：" prop="create_time">
								{{ telephoneCallForm.create_time * 1000 | toDateString }}
							</el-form-item>
							<el-form-item label="订单号：" prop="order_id">
								{{ telephoneCallForm.order_id }}
							</el-form-item>
							<el-form-item label="订单类型：" prop="type_name">
								{{ telephoneCallForm.type_name }}
							</el-form-item>
							<el-form-item label="发单起点所在地：" prop="place_address">
								{{ telephoneCallForm.place_address }}
							</el-form-item>
							<el-form-item label="发单终点所在地：" prop="target_address">
								{{ telephoneCallForm.target_address }}
							</el-form-item>
							<el-form-item label="语音时长：" prop="jiequ_audio_length">
								{{ telephoneCallForm.jiequ_audio_length }}秒
							</el-form-item>
							<el-form-item label="用户语音：" prop="jiequ_audio">
								<audio controls="controls" v-if="isReloadData">
									<source :src="telephoneCallForm.jiequ_audio" type="audio/ogg">
									<source :src="telephoneCallForm.jiequ_audio" type="audio/mpeg">
								</audio>
							</el-form-item>
							<el-form-item label="接单司机：" prop="driver_name">
								{{ telephoneCallForm.driver_name }}
							</el-form-item>
							<el-form-item label="司机手机号：">
								{{ telephoneCallForm.driver_phone }}
							</el-form-item>
							<el-form-item label="接单时间：" prop="receive_time">
								{{ telephoneCallForm.receive_time * 1000 | toDateString }}
							</el-form-item>
							<el-form-item label="抢单司机位置：" prop="order_price">
								{{ telephoneCallForm.grab_driver_address }}
							</el-form-item>
							<!-- <el-form-item label="完成时间：" prop="complete_time">
										{{ telephoneCallForm.complete_time*1000 | toDateString }}
									</el-form-item> -->
							<el-form-item label="支付方式：" prop="remark">
								{{ telephoneCallForm.remark }}
							</el-form-item>
							<el-form-item label="订单状态：" prop="status_name">
								{{ telephoneCallForm.status_name }}
							</el-form-item>
						</div>
					</div>
				</el-form>
			</el-card>
		</el-dialog>
	</div>
</template>

<script>
import {
	mapGetters
} from "vuex";
import uploadImage from '@/components/uploadImage'
import download from '@/components/ListDownload/index'
export default {
	name: "SysUser",
	components: {
		uploadImage,
		download
	},
	data() {
		return {
			table: {
				url: '/driver/index',
				where: {
					m_type: 1
				}
			}, // 表格配置
			table1: {
				url: '/driver/driver_check_log',
				where: {}
			}, // 表格配置

			changePro: false,
			changeCity: false,
			changeArea: false,
			jd_changePro: false,
			jd_changeCity: false,
			jd_changeArea: false,
			choose: [], // 表格选中数据
			showEdit: false, // 是否显示表单弹窗
			dialogVisiblePlans: false,
			blackReason: {},
			editForm: {}, // 表单数据
			editRules: { // 表单验证规则
				phone: [{
					required: true,
					message: '请输入手机号',
					trigger: 'blur'
				}, {
					pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
					message: '请输入正确的手机号',
					trigger: 'blur',
				}],
				name: [{
					required: true,
					message: '请输入昵称',
					trigger: 'blur'
				}],
				pid: [{
					required: true,
					message: '请选择省份',
					trigger: 'change'
				}],
				sex: [{
					required: true,
					message: '请选择性别',
					trigger: 'change'
				}],
				idcard: [{
					required: true,
					message: '请输入身份证号',
					trigger: 'blur'
				},
				{
					pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
					message: '请输入正确的身份证号',
					trigger: 'blur'
				}
				],
				car_number: [{
					required: true,
					message: '请输入车牌号',
					trigger: 'blur'
				}],
				car_persons: [{
					required: true,
					message: '请输入车座数量',
					trigger: 'blur'
				}]
			},
			provArr: [],
			cityArr: [],
			districtArr: [],
			jd_provArr: [],
			jd_cityArr: [],
			jd_districtArr: [],
			checkForm: {},
			telephoneCallForm: {},
			recordsList: [],
			shieldList: [],
			startTime: '',
			endTime: '',
			driverData: {},
			dialogTableVisibleEdit: false,
			dialogTableVisibleCheck: false,
			dialogTableVisibleRecords: false,
			dialogTableVisibleCarRecords: false,
			dialogShieldDetail: false,
			dialogTableVisibleChatRecord: false,
			dialogViewOrderDetail: false,
			pageSize: 10,
			pageNumber: 1,
			shieldPageNumber: 1,
			recordsTotal: 1000,
			count: 0,
			recordsShow: false,
			shieldShow: false,
			recordsId: '',
			shieldId: '',
			loading: true,
			pid: '',
			cid: '',
			aid: '',
			tableend_time: '',
			tablestart_time: '',
			info: {
				name: '',
				number: '',
				phone: ''
			},
			zoom: 12,
			charList: [],
			driverInfo: {},
			ampDisplay: false,
			ampSrc: '',
			orderInfo: {},
			isReloadData: true,
		}
	},
	created() {


		this.$http.get('/common/province_list').then(res => {
			let data = JSON.parse(res.data)
			this.provArr = data
			this.jd_provArr = data
		})

	},
	computed: {
		...mapGetters(["permission"]),
	},
	mounted() { },
	methods: {
		reSetWhere() {
			this.table.where = { m_type: 1 };
			this.$refs.childDownload.clearWhere();
			this.$refs.table.reload();

		},
		handleClose() {
			this.ampDisplay = false
		},
		hanlePicture(src) {
			this.ampDisplay = true
			this.ampSrc = src
		},
		//拉黑查看订单聊天
		viewOrderChat(row) {
			console.log('row', row);
			let that = this;
			that.dialogTableVisibleChatRecord = true;
			that.$http.post('/driver/getOrderLog', {
				channel: row.channel,
				order_id: row.order_id
			}).then(res => {
				console.log('获取聊天的res', res);
				if (res.data.code == 0) {
					let data = res.data.data;
					data.log.forEach(function (item) {
						item.content = that.$util.jsonToObject(item.content);
						item.isTimeShow = 1
						if (item.content.S == 21) {
							item.content.P = that.$util.jsonToObject(item.content.P);
							item.isPlay = true
							if (item.content.P.LONG == 1) {
								item.bgWidth = '8rem'
							}
							item.bgWidth = 8 + 0.05 * (parseInt(item.content.P.LONG) - 1) + 'rem'
						}
						if (item.content.S == 26) {
							item.content.P.POINT = item.content.P.POINT.split(',')
							//console.log(item.content.P.POINT)
						}
					});
					for (var i = 0; i < data.log.length; i++) {
						for (var j = i + 1; j < data.log.length - 1; j++) {
							if (data.log[i].create_time == data.log[j].create_time) {
								data.log[j].isTimeShow = 0 //不显示时间
							} else {
								data.log[j].isTimeShow = 1
							}
						}
					}
					that.charList = data.log
					that.driverInfo = data.driver
					that.orderInfo = data.order_info
				} else {

				}

			})
		},
		//拉黑查看订单详情
		viewOrderDetail(row) {
			console.log('row', row);
			let that = this;
			that.dialogViewOrderDetail = true;
			that.$http.post('/driver/getOrderLog', {
				channel: row.channel,
				order_id: row.order_id
			}).then(res => {
				console.log('95128订单详情的res', res);
				if (res.data.code == 0) {
					that.telephoneCallForm = res.data.data;
					console.log('that.telephoneCallForm', that.telephoneCallForm);
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		closeShow() {
			var middlePlay = document.querySelectorAll('.pasuVideo')
			for (let i = 0; i < middlePlay.length; i++) {
				middlePlay[i].currentTime = 12
				middlePlay[i].pause()
			}
			this.autoPlays = false
		},
		// 导出数据参数
		exportOrderParams() {
			const query = this.table.where
			//console.log('8877777733333333333333', this.table.where);
			const selectionIds = this.choose.map(item => {
				return item.id
			})

			return {
				ids: selectionIds,
				...query
			}
		},
		dropClick(command, row) {
			if (command === 'edit') { // 查看用户信息
				this.edit()
			} else if (command === 'remove') { //删除
				this.remove()
			} else if (command === 'out_list') { //出车记录
				this.handleCarRecords(row)
			} else if (command === 'check_list') { //检测记录
				this.handleRecords(row)
			} else if (command === 'edit') { //修改用户信息
				this.edit(row)
			} else if (command === 'forlimit') { //限号
				this.forlimit(row)
			} else if (command === 'limitWithDraw') { //限制提现
				this.limitWithDraw(row)
			} else if (command === 'openWithDraw') { //恢复提现
				this.openWithDraw(row)
			} else if (command === 'shieldDetail') {
				this.shieldDetail(row)
			}
			if (command === 'blackData') { //拉入黑名单
				this.dialogVisiblePlans = true
				this.blackReason = row
			}
		},

		/**
		 *选择省
		 **/
		handleChangeProv(e) {
			/** 获取被选省省份的pid**/
			let pid = ''
			this.provArr.forEach(function (item) {
				if (item.pid == e) {
					pid = item.pid
				}
			})
			/** 根据被选省份的pid获取省市下面的市**/
			this.$http.post('/common/city_list', {
				pid: pid
			}).then(res => {
				let data = JSON.parse(res.data)
				this.cityArr = data
				/** 选择省份清空市县**/
				this.table.where.cid = ''
				this.table.where.aid = ''
			})
		},
		/**
		 *选择市
		 **/
		handleChangeCity(e) {
			if (e) {
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function (item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
			} else {
				this.table.where.pid = ''
				this.table.where.aid = ''
			}
		},
		handleChangeProv1(e) {
			/** 获取被选省省份的pid**/
			this.changePro = true
			let pid = ''
			this.provArr.forEach(function (item) {
				if (item.pid == e) {
					pid = item.pid
				}
			})
			/** 根据被选省份的pid获取省市下面的市**/
			this.$http.post('/common/city_list', {
				pid: pid
			}).then(res => {
				let data = JSON.parse(res.data)
				this.cityArr = data
				/** 选择省份清空市县**/
				this.editForm.cname = ''
				this.editForm.aname = ''
			})
		},
		/**
		 *选择市
		 **/
		handleChangeCity1(e) {
			if (e) {
				/** 获取被选市的cid**/
				this.changeCity = true
				let cid = ''
				this.cityArr.forEach(function (item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aname = ''
				})
			} else {
				this.editForm.pname = ''
				this.editForm.aname = ''
			}
		},
		handleDis() {
			this.changeArea = true
		},

		/**
		 *修改用户信息
		 **/
		handleEdit(row) {
			console.log('row', row);
			this.dialogTableVisibleEdit = true
			this.editForm = row;

			if (this.editForm.pid > 0) {
				this.$http.post('/common/city_list', {
					pid: this.editForm.pid
				}).then(res => {
					let cdata = JSON.parse(res.data)
					this.cityArr = cdata
				})
			}
			if (this.editForm.cid > 0) {
				this.$http.post('/common/area_list', {
					cid: this.editForm.cid
				}).then(res => {
					let adata = JSON.parse(res.data)
					this.districtArr = adata
				})
			}

			if (this.editForm.jd_pid > 0) {
				this.$http.post('/common/city_list', {
					pid: this.editForm.jd_pid
				}).then(res => {
					let cdata = JSON.parse(res.data)
					this.jd_cityArr = cdata
				})
			}
			if (this.editForm.jd_cid > 0) {
				this.$http.post('/common/area_list', {
					cid: this.editForm.jd_cid
				}).then(res => {
					let adata = JSON.parse(res.data)
					this.jd_districtArr = adata
				})
			}
		},
		/**
		 *查看用户信息
		 **/
		handlecheck(row) {
			this.dialogTableVisibleCheck = true
			// if(row.is_carry==1){
			//   row.is_carry='载客'
			// }else{
			//   row.is_carry='空闲'
			// }
			// if(row.status==2){
			//   row.status='正常'
			// }else(
			//   row.status='待完善信息'
			// )
			this.checkForm = row;
		},
		/**
		 * 被乘拉黑明细
		 */
		shieldDetail(row) {
			console.log('拉黑明细的row', row);
			this.dialogShieldDetail = true;
			this.shieldId = row.driver_id;
			this.shieldDetailList(row.driver_id);
		},
		//拉黑分页
		shieldCurrentChange(e) {
			console.log('e>>>', e);
			this.shieldPageNumber = e;
			console.log('this.shieldId', this.shieldId);
			this.shieldDetailList(this.shieldId)
		},
		//拉黑明细列表
		shieldDetailList(id) {
			this.shieldList = [];
			this.$http.get('/driver/blackDetail?id=' + id + '&limit=' + this.pageSize + '&page=' + this.shieldPageNumber).then(res => {
				console.log('拉黑的res', res)
				if (res.data.code == 0) {
					this.info.name = res.data.data.data.true_name;
					this.info.number = res.data.data.data.car_number;
					this.info.phone = res.data.data.data.phone;
					this.count = res.data.count;
					if (res.data.count == 0 || res.data.count == 'null') {
						this.shieldShow = false
					} else {
						this.shieldShow = true
					}
					if (res.data.data.list.length) {
						this.shieldList = res.data.data.list
						console.log('this.shieldList', this.shieldList);
					}
				}
			})
		},
		closeDialog() {
			var middlePlay = document.querySelectorAll('.pasuVideo')
			for (let i = 0; i < middlePlay.length; i++) {
				middlePlay[i].pause()
				middlePlay[i].currentTime = 0
			}
		},
		/**
		 *检测记录  pageSize（每页的条数），pageNumber（页码）
		 **/
		handleRecords(row) {
			this.dialogTableVisibleRecords = true
			this.recordsId = row.id
			this.recordsUId = row.uid
			this.getRecords(row.uid)
			this.recordsId = row.uid;
		},
		getRecords(id) {
			this.recordsList = [];
			var formData = {
				id: id,
				limit: this.pageSize,
				page: this.pageNumber,
				start_time: this.startTime,
				end_time: this.endTime

			}
			this.$http.post('/driver/test_log', formData).then(res => {
				console.log('人脸识别的res', res);
				// var data = JSON.parse(res.data)
				var data = res.data;
				this.recordsTotal = data.count
				if (data.count == 0 || data.count == 'null') {
					this.recordsShow = false
				} else {
					this.recordsShow = true
				}
				if (data.data.length) {
					this.recordsList = data.data

				}
			})
		},
		/**
		 *查询
		 **/
		searchRecords() {
			this.getRecords(this.recordsUId)
		},
		/**
		 *分页
		 **/
		handleCurrentChange(e) {
			//console.log(e)
			this.pageNumber = e
			this.getRecords(this.recordsId)
		},
		/**
		 *查看出车记录
		 **/
		handleCarRecords(row) {
			this.dialogTableVisibleCarRecords = true
			const loading = this.$loading({
				lock: true,
				target: document.querySelector(".records")
			});
			this.$http.post('/driver/driver_log', {
				id: row.id
			}).then(res => {
				loading.close()
				let data = JSON.parse(res.data)
				this.driverData = data.driver_data
				let xData = data.x_datas
				let yData = data.y_datas
				this.$nextTick(() => {
					let dom = document.getElementById('main')
					let myChart = this.$echarts.init(dom);
					let option;
					option = {

						toolbox: {
							show: true,
							feature: {
								saveAsImage: { //保存图片
									show: true
								},
							}
						},
						xAxis: {
							type: 'category',
							data: xData
						},
						yAxis: {
							type: 'value'
						},
						series: [{
							data: yData,
							type: 'line',
							itemStyle: {
								normal: {
									color: '#009F95',
									lineStyle: {
										color: '#009F95'
									}
								}
							},
						}],

					};
					option && myChart.setOption(option);
				})
			})
		},

		/**
		 * 显示编辑
		 */
		edit(row) {
			this.$http.get('/driver/info?id=' + row.id).then(res => {
				if (res.data.code === 0) {
					this.editForm = res.data.data;
					this.showEdit = true;
				} else {
					this.$message.error(res.data.msg);
				}
			}).catch(e => {
				this.$message.error(e.message);
			});
		},
		/**
		 * 保存编辑
		 */
		save() {
			this.$refs['editForm'].validate((valid) => {
				if (valid) {
					const loading = this.$loading({
						lock: true
					});
					if (this.changePro == true) {
						this.editForm.pid = this.editForm.pname
						this.changePro = false
					}
					if (this.changeCity == true) {
						this.editForm.cid = this.editForm.cname
						this.changeCity = false
					}
					if (this.changeArea == true) {
						this.editForm.aid = this.editForm.aname
						this.changeArea = false
					}
					//console.log(this.jd_changePro)
					if (this.jd_changePro == true) {
						this.editForm.areas = this.editForm.jd_aname;
					}

					this.$http.post('/driver/edit', this.editForm).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.showEdit = false;
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
							this.dialogTableVisibleEdit = false
							this.changePro = false
							this.changeCity = false
							this.changeArea = false
							this.jd_changePro = false
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				} else {
					return false;
				}
			});
		},
		/**
		 * 刪除(批量刪除)
		 */
		remove(row) {
			//console.log(row)
			if (!row) { // 批量删除
				if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
				let ids = this.choose.map(d => d.id);
				this.$confirm('确定要删除选中的司机吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/driver/delete', {
						id: ids
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				}).catch(() => 0);
			} else { // 单个删除
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/driver/delete', {
					id: row.id
				}).then(res => {
					loading.close();
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			}
		},
		blackData() {
			const loading = this.$loading({
				lock: true
			});
			this.$http.post('/driver/forblack', {
				id: this.blackReason.id,
				black_data: this.blackReason.black_data
			}).then(res => {
				loading.close();
				if (res.data.code === 0) {
					this.$message({
						type: 'success',
						message: res.data.msg
					});
					this.dialogVisiblePlans = false;
					this.$refs.table.reload();
				} else {
					this.$message.error(res.data.msg);
				}
			}).catch(e => {
				loading.close();
				this.$message.error(e.message);
			});
		},
		forlimit(row) {

			this.$confirm('确定把该司机限号吗?', '提示', {
				type: 'warning'
			}).then(() => {
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/driver/forlimit', {
					id: row.id
				}).then(res => {
					loading.close();
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			})

		},
		// 限制提现
		limitWithDraw(row) {
			this.$confirm('确定把司机限制提现吗？', '提示', {
				type: 'warning'
			}).then(() => {
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/driver/update_field', {
					id: row.id,
					key: 'is_tx',
					value: 0
				}).then(res => {
					loading.close();
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			})

		},
		// 限制提现
		openWithDraw(row) {
			this.$confirm('确定把司机恢复提现吗？', '提示', {
				type: 'warning'
			}).then(() => {
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/driver/update_field', {
					id: row.id,
					key: 'is_tx',
					value: 1
				}).then(res => {
					loading.close();
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			})

		},
		/* 导出数据Excel */
		exportExcel() {
			let info = JSON.parse(JSON.stringify(this.table.where));
			// let name = this.table.where.name;
			// let phone = this.table.where.phone;
			// let cid = this.table.where.cid;
			// let pid = this.table.where.pid;
			// let area_id = this.table.where.area_id;
			// let page = this.table.where.area_id;
			//console.log('-----------------user data export')
			this.$http
				// .get("/user/exportExcel", {'name':name,'phone':phone,'pid':pid,'cid':cid,'area_id':area_id})
				.get("/driver/exportExcelOld", info)
				.then((res) => {
					let data = res.data;
					if (data.code == 0) {
						// 下载文件
						window.location.href = data.data;
						this.$message({
							type: "success",
							message: "导出成功",
						});
					} else {
						this.$message.error("导出失败");
					}
				})
		},


		handleChangeProv2(e) {
			/** 获取被选省省份的pid**/
			let pid = ''
			this.jd_provArr.forEach(function (item) {
				if (item.pid == e) {
					pid = item.pid
				}
			})
			/** 根据被选省份的pid获取省市下面的市**/
			this.$http.post('/common/city_list', {
				pid: pid
			}).then(res => {
				let data = JSON.parse(res.data)
				this.jd_cityArr = data
				/** 选择省份清空市县**/
				this.editForm.jd_cname = ''
				this.editForm.jd_aname = ''
				this.editForm.jd_aid = ''
			})
		},
		/**
		 *选择市
		 **/
		handleChangeCity2(e) {
			if (e) {
				/** 获取被选市的cid**/
				let cid = ''
				this.jd_cityArr.forEach(function (item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.jd_districtArr = data
					/** 选择省份清空县**/
					this.editForm.jd_aname = ''
					this.editForm.jd_aid = ''
				})
			} else {
				this.editForm.jd_pname = ''
				this.editForm.jd_aname = ''
				this.editForm.jd_aid = ''
			}
		},
		handleDis2(e) {
			this.xiu = true;
			this.jd_changePro = true
			//console.log(e)

			var editaid = [];
			// 多选
			this.jd_districtArr.forEach(function (item) {
				e.forEach(function (i) {
					if (item.aid == i || item.name == i) {
						editaid.push(item.aid)
					}
				})
			})
			//console.log(editaid)
			this.$set(this.editForm, this.editForm.jd_aname, editaid)
			this.editForm.jd_aname = editaid
		},
		birthdayChange1() {
			//console.log(this.tableend_time)
			this.startTime = this.tableend_time

		},
		birthdayChange2() {
			//console.log(this.tablestart_time)
			this.endTime = this.tablestart_time;
		},

	}
}
</script>

<style scoped>
.ele-block .el-upload-dragger {
	width: 100%;
}

/* /deep/.el-dialog {
	margin-top: 40px !important;
} */
.el-table+.el-pagination {
	margin: 15px 0;
}

.info {
	font-size: 18px;
	color: #000;
	margin: 0 30px;
}

.info:first-child {
	margin: 0 10px 0 10px;
}

/deep/.el-dialog {
	margin-top: 0px !important;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}</style>
